body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  background: rgba(242, 242, 242, 1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1000px) {
  body {
    background-color: white;
  }
}

code {
  font-family: 'Arial', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
